import { render, staticRenderFns } from "./FlipPixel.vue?vue&type=template&id=5cbec1f6&scoped=true"
import script from "./FlipPixel.vue?vue&type=script&lang=js"
export * from "./FlipPixel.vue?vue&type=script&lang=js"
import style0 from "./FlipPixel.vue?vue&type=style&index=0&id=5cbec1f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbec1f6",
  null
  
)

export default component.exports